export enum CountryCodes {
  US = 1,
  Canada = 2,
}

export enum TrialStatus {
  created = "created",
  published = "published",
  assigned = "assigned",
  deleted = "deleted",
}

export enum Treatment {
  Yes = "Yes",
  No = "No",
}

export enum RandomizationTypeEnum {
  RCB = "RCB",
  RCB_With_Grouping = "RCB with Grouping",
  None = "None",
}

export enum TreatmentTypeEnum {
  split_plot = "Split Plot",
  factorial = "Factorial",
}
