import moment from "moment";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";
import { AppConstants } from "../constants";
import { ILoginLOLResponse } from "../models/ILoginLOLResponse.model";

export const helperFunctions = {
  getPageTitle(path: string): string {
    const page = AppConstants.pages.find((page) => page.path === path);
    return page ? page.title : "Master Data";
  },
  getMainPageTitle(path: string): string {
    const page = AppConstants.mainPages.find((page) => page.path === path);
    return page ? page.title : "Dashboard";
  },

  getLOLToken(): ILoginLOLResponse | null {
    const token = localStorage.getItem(AppConstants.LOLLoginSessionKey);
    return token ? (JSON.parse(token) as ILoginLOLResponse) : null;
  },

  addQueryParam(
    url: string,
    key: AgTechQueryParam,
    values: string[] | number[] | boolean[]
  ): string {
    return url.indexOf("?") > 0
      ? `${url}&${key}=${values.join(",")}`
      : `${url}?${key}=${values.join(",")}`;
  },

  updatePatchRequestParameter(path: string, value: any, operationType: string = 'replace') {
    return { op: operationType, path: '/' + path, value: value };
  },

  getCurrentSeason() {
    const date = new Date();
    let currentSeason;
    const currentYear = date.getFullYear();
   // const seasonDate = new Date(currentYear, 10, 1);
    // if (date >= seasonDate) {
    //   currentSeason = currentYear + 1;
    // } else {
     // currentSeason = currentYear;
    //}

    currentSeason = currentYear + 1;
    return currentSeason;
  },
  getErrorMessage(errorRes: any) {
    const {errors , message} = errorRes ?? {}
    if(errors?.length>0){
      return errors?.[0]?.message
    }
    return message ?? 'Something went wrong!'
  },
  formattedDate(data: any) {
    if(!data) return '';
    return moment(data).format("YYYY-MM-DD");
  },
  isDashboard(path: string) {
    return path !== "/main/dashboard";
  },
  readableFormattedDate(data: any) {
    if(!data) return '';
    return moment(data).format("MM-DD-YYYY");
  },

};
