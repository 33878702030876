import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "packages/lib-utils";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
// import App from "app/App";
import { store } from "./app/utils/store/store";
import React from "react";

const App = React.lazy(() => import('app/App'));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
  <ThemeProvider>
      <App />
      <ToastContainer limit={1} position="top-right" autoClose={3000}/>
  </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
