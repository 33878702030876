import { createSlice } from "@reduxjs/toolkit";
import { ITrial } from "packages/lib-utils/src/models/ITrial.model";

// Define the TS type for the counter slice's state
export interface TrialsState {
  trailRequest:any,
  trailDetail?: ITrial | null,
}

// Define the initial value for the slice state
const initialState: TrialsState = {
  trailRequest: null,
  trailDetail : null
};
export const trailsSlice = createSlice({
  name: "trails",
  initialState,
  reducers: {
    setTrialRequest: (state, action) => {
      state.trailRequest = action.payload;
    },
    setTrialDetail: (state, action) => {
      state.trailDetail = action.payload;
    },
    resetTrialStates: (state) => {
      state = initialState;
    },
  },
});

// Export the generated action creators for use in components
export const { setTrialRequest, resetTrialStates, setTrialDetail } = trailsSlice.actions;

// Export the slice reducer for use in the store configuration
export default trailsSlice.reducer;
