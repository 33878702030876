import React, { PropsWithChildren } from 'react';
import { ThemeProvider  as StyledThemeProvider } from '@mui/material/styles';
import { themeConfig } from './themeConfig';


const ThemeProvider = ({ children }: PropsWithChildren) => {
  const theme = {...themeConfig};
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

export default ThemeProvider;
