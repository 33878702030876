import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { Action, ThunkAction } from '@reduxjs/toolkit'
import appFilterReducer from './AppFilters/AppFiltersSlice';
import trailsReducer from './Trials/TrailsSlice';

import {  persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
const persistConfig = {
  key: 'root',
  storage,
};
const rootReducer = combineReducers({
  appFilters: appFilterReducer, // Your reducers go here
  trails:trailsReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
})

// Infer the type of `store`
export type AppStore = typeof store
export const persistor = persistStore(store);
export type RootState = ReturnType<AppStore['getState']>
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch']
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>