import { createSlice } from "@reduxjs/toolkit";
import { ICrop } from "packages/lib-utils/src/models";
import { ISeason } from "packages/lib-utils/src/models/ISeason.model";
import { ITrial } from "packages/lib-utils/src/models/ITrial.model";

// Define the TS type for the counter slice's state
export interface FilterState {
  filters: {
    crops: ICrop | null;
    season: ISeason | null;
  };
}

// Define the initial value for the slice state
const initialState: FilterState = {
  filters: {
    crops: null,
    season: null,
  },
};
export const appFiltersSlice = createSlice({
  name: "appfilter",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilter: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Export the generated action creators for use in components
export const { setFilters, resetFilter } = appFiltersSlice.actions;

// Export the slice reducer for use in the store configuration
export default appFiltersSlice.reducer;
