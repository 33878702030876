import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import { subscribe } from 'diagnostics_channel';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

let RXFeatureGeometry: any = null;
const RXFeatureGeometry$ = new BehaviorSubject<any>(RXFeatureGeometry);

export const rxStore = {
  // storing the geometry of the prescription map drawn by sketch widget if it is has some trial placed
  RXFeatureMapGeometry: {
    setRXFeatureGeometry: (geo: any) => {
      RXFeatureGeometry$.next(geo);
    },
    subscribe: (setState: any) => RXFeatureGeometry$.subscribe(setState),
    clear: () => {
      RXFeatureGeometry = null;
      RXFeatureGeometry$.next(RXFeatureGeometry);
    }
  },

};
