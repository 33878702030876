import { subscribe } from 'diagnostics_channel';
import { BehaviorSubject } from 'rxjs';

let initialGisInstanceState;
const gisInstanceLevel$ = new BehaviorSubject(initialGisInstanceState);

export const arcgisStore = {
    gisInstance: {
        subscribe: (setState: any) => gisInstanceLevel$.subscribe(setState),
        setGisInstance: (gisInstance: any) => {
            gisInstanceLevel$.next(gisInstance);
        },
    },
};
