import { createTheme } from "@mui/material/styles";
import { themeColors } from "../../colors";

// Define a custom palette interface extending the default theme
interface CustomPalette {
  customColor: {
    main: string;
    error: string;
  };
}

declare module "@mui/material/styles" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

export const themeConfig = createTheme({
  palette: {
    primary: {
      main: themeColors.background.Primary, // Your custom primary color
      contrastText: themeColors.font.White, // Text color for primary buttons
    },
    secondary: {
      main: "#1C5D40", // Your custom secondary color
      contrastText: themeColors.font.White, // Text color for secondary buttons
    },
    error: {
      main: themeColors.background.Danger, // Your custom secondary color
      contrastText: themeColors.font.White, // Text color for secondary buttons
    },
    customColor: {
      main: "#4caf50", // Your custom color
      error: "#D25F5F",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    fontSize: 14,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
