export const AppConstants = {
  LOLLoginSessionKey: "LOLLoginSessionKey",
  pages: [
    { title: "Master Data", path: "/masterdata" },
    { title: "Users", path: "/masterdata/users" },
    { title: "Crops", path: "/masterdata/crops" },
    { title: "Teams", path: "/masterdata/teams" },
    { title: "Treatments", path: "/masterdata/treatments" },
    { title: "Products", path: "/masterdata/products" },
    { title: "Locations", path: "/masterdata/locations" },
    { title: "Seasons", path: "/masterdata/seasons" },
  ],
  mainPages: [
    { title: "Inventory", path: "/main/inventory" },
    { title: "Trials", path: "/main/trials" },
    { title: "Trial Locations", path: "/main/triallocations" },
    { title: "Mapping Sheets", path: "/main/mappingSheets" },
    { title: "Mapping Sheet:", path: "/main/mappingSheets/teams" },
  ],
  COMMON_TEXT: {
    SEARCH_HINT_TEXT: "Press enter to search",
    DELETE_CONFIRM_TITLE: "Delete Confirmation",
    DELETE_CONFIRM_DESCRIPTION: "Are you sure you want to delete?",
    CONFIRM_DIALOG_YES: "Yes",
    CONFIRM_DIALOG_NO: "No",
    SHOW_ALL: "Show All",
    SEARCH_BY_USER_NAME: "Search By User Name",
    DISCARD_CONFIRM_DESCRIPTION:
      "All changes will be lost if you discard. Do you want to continue?",
    DISCARD_CONFIRM_TITLE: "Discard Confirmation",
  },
  APP_DEFAULT_FILTER:{
     SELECTED_CROP:"CORN"
  }
};
