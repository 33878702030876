export enum ColorEnum {
  DarkBlue = "#0B3F54",
  Blue = "#4367E5",
  LightBlue = "#6FC8C0",
  SeaBlue = "#144E65",
  LighSky = "#D5E8F5",
  Sky = "#F3F8FF",
  SeaSky = "#EAF2FC",
  Yellow = "#C3BF52",
  Danger = "#D25F5F",
  Gray = "#333333",
  DarkGray = "#242424",
  LightGray = "#F1F1F1",
  Grayish = "#6C6C6C",
  Black = "#000000",
  GrayishWhite = "#F8F8F8",
  White = "#FFFFFF",
  SilverGray = "#CCCCCC",
  GraniteGray = "#666666",
  MediumGray = "#9C9C9C",
  Green = "#13A446",
  Orange = "#DA7F00",
  BluishCyan = "#3C6576",
  MediumGrayish = "#DDDDDD",
  SkyBlue = "#2E79DC",
}
